import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'layouts/Layout';
import { Element } from 'react-scroll';

import SEO from 'components/seo/seo';
import { injectIntl } from 'gatsby-plugin-intl';
import withLocale from 'hoc/withLocale';
import Footer from 'components/Footer/Footer';
import Heading from 'components/Subpage/Heading/Heading';
import NewsList from '../components/NewsList/NewsList';
import {
  StyledPageWrapper,
  StyledInnerWrapper,
} from '../components/Subpage/SubpageWrapper/SubpageWrapperStyles';

const News = ({ currentLocale }) => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      pageDataPl: allStrapiNewsPage(filter: { locale: { eq: "pl" } }) {
        edges {
          node {
            metaDesc
            title
          }
        }
      }
      sectionsPl: allStrapiNewsSections(filter: { locale: { eq: "pl" } }) {
        edges {
          node {
            news {
              title
              shortDesc
              link
              image {
                url
              }
              imageAlt
            }
            created_at
            locale
          }
        }
      }
      pageDataEn: allStrapiNewsPage(filter: { locale: { eq: "en" } }) {
        edges {
          node {
            metaDesc
            title
          }
        }
      }
      sectionsEn: allStrapiNewsSections(filter: { locale: { eq: "en" } }) {
        edges {
          node {
            news {
              title
              shortDesc
              link
              image {
                url
              }
              imageAlt
            }
            locale
            created_at
          }
        }
      }
    }
  `);

  let edges;
  let pageData;

  if (data && currentLocale === 'pl') {
    edges = data.sectionsPl.edges;
    pageData = data.pageDataPl.edges[0].node;
  } else if (data && currentLocale === 'en') {
    edges = data.sectionsEn.edges;
    pageData = data.pageDataEn.edges[0].node;
  }

  return (
    <>
      <Layout>
        <SEO title={pageData.title} description={pageData.metaDesc} />
        <StyledPageWrapper>
          <StyledInnerWrapper>
            <Heading>{pageData.title}</Heading>
            {edges ? (
              <NewsList edges={edges} />
            ) : (
              <p>{pageData.listEmptyText}</p>
            )}
          </StyledInnerWrapper>
        </StyledPageWrapper>
        <Element name="navigation_footer">
          <Footer />
        </Element>
      </Layout>
    </>
  );
};

export default injectIntl(withLocale(News));
