import styled from 'styled-components';

export const NewsContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;

  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

export const Image = styled.img`
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.mq.lg} {
    max-width: 320px;
  }
  ${({ theme }) => theme.mq.xl} {
    max-width: 360px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 480px;
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${({ theme }) => theme.mq.lg} {
    padding: 0 28px;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
  padding-right: 10rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 2.4rem;
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
`;

export const NewsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

export const SmallText = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;

  ${({ theme }) => theme.mq.md} {
    font-size: 1.2rem;
  }
`;
