import styled from 'styled-components';

export const StyledHeading = styled('h1')`
  text-align: center;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.8rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 4.4rem;
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
`;
