import React from 'react';
import { Link } from 'gatsby-plugin-intl';

import Text from '../Subpage/Text/Text';
import * as S from './NewsListStyled';

const NewsListEntry = ({ news }) => {
  const { image, title, shortDesc, date, locale, link } = news;

  const dateLocale = {
    pl: 'pl-PL',
    en: 'en-GB',
  };

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const formatedDate = new Date(date).toLocaleDateString(
    dateLocale[locale],
    options
  );

  const dateInfo = `${locale === 'pl' ? 'Dodano:' : 'Added:'} ${formatedDate}`;

  return (
    <Link to={`/${link}`}>
      <S.NewsContentWrapper>
        <S.Image src={process.env.GATSBY_API_URL + image.url} alt={title} />

        <S.TextWrapper>
          <div>
            <S.Title>{title}</S.Title>
            <Text>{shortDesc}</Text>
          </div>
          <S.SmallText>{dateInfo}</S.SmallText>
        </S.TextWrapper>
      </S.NewsContentWrapper>
    </Link>
  );
};

export default NewsListEntry;
