import React from 'react';
import NewsListEntry from './NewsListEntry';
import { NewsListWrapper } from './NewsListStyled';

const NewsList = ({ edges }) => {
  const formatedNews = edges.map(edge => ({
    ...edge.node.news,
    locale: edge.node.locale,
    date: edge.node.created_at,
  }));

  return (
    <NewsListWrapper>
      {formatedNews.map(item => (
        <NewsListEntry key={item.id} news={item} />
      ))}
    </NewsListWrapper>
  );
};

export default NewsList;
